<template>
  <ion-page>
    <ion-content :fullscreen="true" v-if="game || !route.params.id">
      <IngameFab :game="game"/>
    </ion-content>
  </ion-page>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {getGame, getGames, getCategories} from "@/data/games";
import {getCategories as getCategoryMap, getCategory} from "../data/categories"
import { defineComponent, ref } from 'vue';
import {
  IonContent,
  IonPage,
  menuController,
  modalController, useBackButton,
  useKeyboard
} from "@ionic/vue";
import {ScreenOrientation} from "@ionic-native/screen-orientation";
import {Storage} from "@ionic/storage";
import socket from "../socket";
import * as Phaser from "phaser";
import {singletons} from "@/data/singletons";
import Friend from "./Friend";
import IngameFab from "@/components/IngameFab";
import {appState} from "@/data/reactive";
import {getCurrentAvatar} from "../data/avatars";
import {Capacitor} from "@capacitor/core";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {checkForUpdates} from "@/utils";
import AdminLog from "@/views/AdminLog.vue";

export default defineComponent({
  name: "Games",
  components:{
    IngameFab,
    IonContent,
    IonPage,
  },
  setup() {
    try {
      //ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    } catch (err) {}
    const route = useRoute();
    const router = useRouter();
    const game = getGame(parseInt(route.params.id));
    const gameId = ref(null);
    gameId.value = 0;
    useBackButton(10,()=>{
      router.replace(`/games/${route.path?.match(/\/games\/[^/]+\/?$/) ? "" : game.category}`);
    })
    return { game, router, route,
      singletons,
      gameId
    }
  },
  data() {
    return {
      chatModalWasRecentlyClosed:false,
      handleChatModalClosed:null,
      currentAvatar:null,
    }
  },
  watch: {
    $route(to,from) {
      appState.lastRoute = {...from};

      // reload if switching *directly* from game to game
      if (from.params.id && to.params.id && to.params.id!==from.params.id)
        this.$router.go();
    }
  },
  methods: {
    handleRelease:function() {
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onRelease");
    },
    async openChatModal({userId}) {
      if (this.handleChatModalClosed) {
        clearTimeout(this.handleChatModalClosed);
        this.handleChatModalClosed = null;
      }
      const modal = await modalController
      .create({
        component: Friend,
        componentProps: {
          userId,
          handleCloseModal:()=>{
            modal.dismiss();
            this.chatModalWasRecentlyClosed = true;
            this.handleChatModalClosed = setTimeout(()=>{this.chatModalWasRecentlyClosed=false},3000);
          }
        }
      })
      modal.onWillDismiss().then(()=>{
        this.handleChatModalDismiss();
      })
      return modal.present();
    },
    async openAdminLogModal() {
      const modal = await modalController
          .create({
            component: AdminLog,
            componentProps: {
              handleCloseModal:()=>{
                modal.dismiss();
              }
            }
          })
      return modal.present();
    },
    getContainerStyle() {
      let selectedCategory = this.route.params.category;
      if (!selectedCategory) {
        let gameId = parseInt(this.route.params.id);
        selectedCategory = gameId && getGame(gameId).category || null;
      }
      let encoded = {};
      let gradient = selectedCategory ? getCategoryMap()[selectedCategory].gradientDef : {topleft:"#7d4949",topright:"#7d4949",bottomleft:"#df7126",bottomright:"#df7126"};
      if (gradient) {
        for (let key in gradient) {
          encoded[key] = gradient[key].replace(/#/g,"%23");
        }
      }
      //return "left:0;right:0;bottom:0;top:0"+(selectedCategory ? ";background-color:"+getCategoryMap()[selectedCategory].preloadColor : "");
      return "left:0;right:0;bottom:0;top:0"+(gradient ? ";background-image: url(\"data:image/svg+xml;utf8,%3Csvg preserveAspectRatio='none' viewBox='0 0 1 1' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='g'%3E%3Cstop offset='0' stop-color='%23fff' stop-opacity='0'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='1'%3E%3C/stop%3E%3C/linearGradient%3E%3Cmask id='m'%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23g)'%3E%3C/rect%3E%3C/mask%3E%3ClinearGradient id='a' gradientTransform='rotate(90)'%3E%3Cstop offset='0' stop-color='"+encoded.topright+"'%3E%3C/stop%3E%3Cstop offset='1' stop-color='"+encoded.bottomright+"'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientTransform='rotate(90)'%3E%3Cstop offset='0' stop-color='"+encoded.bottomleft+"'%3E%3C/stop%3E%3Cstop offset='1' stop-color='"+encoded.topleft+"'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23a)' mask='url(%23m)'%3E%3C/rect%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23b)' mask='url(%23m)' transform='translate(1,1) rotate(180)'%3E%3C/rect%3E%3C/svg%3E\")" : "");
    },
  },
  ionViewWillLeave() {
    this.handleRelease();
  },
  ionViewDidLeave() {
    window.removeEventListener("resize",singletons.onPhaserResize);
  },
  async ionViewDidEnter() {
    const store = new Storage();
    this.currentAvatar = await getCurrentAvatar();
    let imports = ()=>[];
    let useCanvas = false;
    let supportRetina = false;
    let supportResize = false;
    let supportFullscreen = false;
    let maxAspect = false;
    let initFullScreen = false;
    this.gameId = (this.game||{}).id||0;
    let selectedCategory = this.route.params.category;
    if (selectedCategory) {
      imports=()=>({
        game:import("@/menu-category")
      });
      supportRetina = true;
      supportResize = true;
    }
    else if (this.route.path.match(/^\/avatar\/?$/)) {
      imports=()=>({
        game:import("@/settings-avatar"),
      });
      supportRetina = true;
      supportResize = true;
    }
    else if (this.gameId) {
      switch (this.gameId) {
        case 1:imports=()=>({game:import("@/game-1"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-1")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 2:imports=()=>({game:import("@/game-2"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 3:imports=()=>({game:import("@/game-3"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-19")}); supportRetina=true; supportResize=true; supportFullscreen=true; break;
        case 4:imports=()=>({game:import("@/game-4"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-2")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 5:imports=()=>({game:import("@/game-5"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-3")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 7:imports=()=>({game:import("@/game-puzzle"),"game-settings":import("@/settings-difficulty"),"network-settings":import("@/network-default"),toast:import("@/game-toast"),success:import("@/game-success")}); useCanvas=false; supportFullscreen=true; supportResize=true; supportRetina=true; break;
        case 8:imports=()=>({game:import("@/game-coloring"),"settings-color":import("@/settings-color"),toast:import("@/game-toast")}); useCanvas=false; supportRetina=true; supportResize=true; break;
        case 9:imports=()=>({game:import("@/game-time02"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),gametask:import("@/task-20")}); supportRetina=true; supportResize=true; supportFullscreen=true; break;
        case 10:imports=()=>({game:import("@/game-time03"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),gametask:import("@/task-21")});  supportRetina=true; supportResize=true; supportFullscreen=true; break;
        case 11:imports=()=>({game:import("@/game-sentences01"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 12:imports=()=>({game:import("@/game-numbers01"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),gametask:import("@/task-6")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 13:imports=()=>({game:import("@/game-ordinals01"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success")}); break;
        case 14:imports=()=>({game:import("@/game-numbers02"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),gametask:import("@/task-7")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 15:imports=()=>({game:import("@/game-maze"),"game-settings":import("@/settings-maze"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),"network-settings":import("@/network-default"),toast:import("@/game-toast"),loading:import("@/game-loading")}); supportRetina=true; break;
        case 16:imports=()=>({game:import("@/game-memory"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),"network-settings":import("@/network-default"),toast:import("@/game-toast"),loading:import("@/game-loading")}); useCanvas=false; supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 17:imports=()=>({game:import("@/game-sudoku"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success")}); supportRetina=true; break;
        case 19:imports=()=>({game:import("@/game-rhymes01"),success:import("@/game-success"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-4")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 20:imports=()=>({game:import("@/game-calc01"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-8")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 21:imports=()=>({game:import("@/game-calc02"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-9")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 22:imports=()=>({game:import("@/game-calc03"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-10")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 23:imports=()=>({game:import("@/game-calc04"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-12")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 24:imports=()=>({game:import("@/game-calc05"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-11")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 25:imports=()=>({game:import("@/game-calc06"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-13")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 26:imports=()=>({game:import("@/game-calc07"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-14")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 27:imports=()=>({game:import("@/game-calc08"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-15")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 28:imports=()=>({game:import("@/game-shapes01"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-16")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 29:imports=()=>({game:import("@/game-shapes02"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-17")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 30:imports=()=>({game:import("@/game-shapes03"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-18")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 31:imports=()=>({game:import("@/game-test-scroller"),bg:import("@/bg-gradient"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 32:imports=()=>({game:import("@/game-test-anim"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 33:imports=()=>({game:import("@/game-writing"),"settings-color":import("@/settings-color"),"game-settings":import("@/settings-difficulty"),toast:import("@/game-toast"),gametask:import("@/task-5")}); useCanvas=false; supportRetina=true; supportFullscreen=true; break;
        case 34:imports=()=>({game:import("@/game-numbers03"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),success:import("@/game-success"),gametask:import("@/task-7")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
        case 35:imports=()=>({game:import("@/game-writing-numbers"),"settings-color":import("@/settings-color"),"game-settings":import("@/settings-difficulty"),toast:import("@/game-toast"),gametask:import("@/task-5")}); useCanvas=false; supportRetina=true; supportFullscreen=true; break;
        case 36:imports=()=>({game:import("@/game-mult01"),"game-settings":import("@/settings-difficulty"),soundunlocker:import("@/game-soundunlocker"),gametask:import("@/task-8")}); supportRetina=true; supportFullscreen=true; supportResize=true; break;
      }
    }
    else {
      let hasUpdates = Capacitor.isNativePlatform();
      imports=()=>({
        game:import("@/menu-main"),
        "tutorial":import("@/tutorial-intro"),
        "tutorial-1":import("@/tutorial-more"),
        soundunlocker:import("@/game-soundunlocker"),
        ...hasUpdates!==false ? {
          "alert-update":import("@/alert-update")
        } : {}
      });
      supportRetina = true;
      supportResize = true;
    }
    const mapPromises = imports();
    Promise.all(Object.values(mapPromises)).then((modules)=> {
      let setup = async ()=>{
        let scenes = modules.map((module)=>module.default);
        let mapScenes = {};
        let i=0;
        Object.keys(mapPromises).forEach((key)=>{
          mapScenes[key] = scenes[i++];
        });
        let ww=window.innerWidth, wh=window.innerHeight;
        const config = {
          type: useCanvas ? Phaser.CANVAS : Phaser.WEBGL,
          scene: scenes,
          scale: {
            mode: Phaser.Scale.NONE,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            parent: "phaser-container",
            width: (maxAspect && ww/wh>maxAspect ? maxAspect*window.innerHeight : window.innerWidth)*(supportRetina ? window.devicePixelRatio : 1),
            height: window.innerHeight*(supportRetina ? window.devicePixelRatio : 1),
            ...supportRetina ? {
              zoom:1.0/window.devicePixelRatio
            } : {}
          },
          physics: {
            default: 'matter',
            matter: {
              debug: false,
              gravity: {
                y: 0.9
              },
              constraintIterations:100
            }
          },
          dom : {
            createContainer : true
          },
          mipmapFilter: "LINEAR_MIPMAP_LINEAR",
          transparent:true,
          //...this.gameId==18 ? Canvas(/*{ antialias: true, logarithmicDepthBuffer:true }*/) : {},
          fps: {
            target: 60,
            forceSetTimeOut: true
          },
          render:{
            defaultPipeline:"MobilePipeline",
            autoMobilePipeline:false,
            maxTextures:16
          }
        };

        let setArgs = ({nativeStorageRoot}) => {
          document.querySelector("#phaser-container").style.cssText = this.getContainerStyle();
          //document.getElementById("phaser-container").insertAdjacentHTML("afterbegin","<div class=\"game-loading pixel-font absolute w-full h-full flex items-center content-center justify-center text-7xl text-white\"><div class=\"classic-2\">Lade</div></div>");
          singletons.phaserGame.router = this.router;
          singletons.phaserGame.route = this.route;
          singletons.phaserGame.id = this.gameId;
          singletons.phaserGame.openChatModal = this.openChatModal;
          singletons.phaserGame.openAdminLogModal = this.openAdminLogModal;
          if (this.gameId===0) {
            singletons.phaserGame.listGames = getGames(selectedCategory);
            singletons.phaserGame.selectedCategory = selectedCategory;
            singletons.phaserGame.listCategories = getCategories();
            singletons.phaserGame.mapCategories = getCategoryMap();
          }
          else {
            singletons.phaserGame.category = this.game.category;
            singletons.phaserGame.categoryDetails = getCategory(this.game.category);
          }
          singletons.phaserGame.onSaveState = (savedState,key=false,send=true)=>{
            let save = {savedState};
            const store = new Storage();
            return new Promise((resolve,reject)=>{
              store.create().then(()=>{
                let prefix = (this.currentAvatar||{}).label||"";
                let _key = `${prefix ? prefix+"_" : ""}${key!==false ? key : "game"+this.gameId}`;
                store.set(_key,save);
                if (send)
                  socket.emit("send-storage",{data:{[_key]:save}})
                resolve();
              })
            });
          };
          singletons.phaserGame.onRestoreState = (key=false)=>{
            return new Promise((resolve,reject)=>{
              store.create().then(async ()=>{
                // if no 'avatar-prefixed' state has been saved yet, load unprefixed state (migration)
                let prefix = (this.currentAvatar||{}).label||"";
                if (key!==false) {
                  let i=0;
                  while (i<3 && !(await store.get(`avatar0${i}_${key}`)))
                    i++;
                  if (i===3)
                    prefix = "";
                }
                store.get(`${prefix ? prefix+"_" : ""}${key!==false ? key : "game"+this.gameId}`).then((data)=>{
                  resolve(data && data["savedState"] || {});
                })
              });
            });
          }
          singletons.phaserGame.onToggleMenu = ()=>{
            menuController.toggle();
          };
          singletons.phaserGame.onFullScreen = ()=>{
            initFullScreen = true;
            singletons.phaserGame.scalemode = Phaser.Scale.FIT;
            setTimeout(()=>{initFullScreen=false},1000);
          }
          singletons.phaserGame.onScrollSave = (scroll)=>{
            appState.mapLastScrollTop[this.$route.path] = scroll;
          }
          singletons.phaserGame.socket = socket;
          singletons.phaserGame.testMode = appState.testMode;
          singletons.phaserGame.adultHumor = appState.adultHumor;
          singletons.phaserGame.nativeStorageRoot = nativeStorageRoot;
          singletons.phaserGame.webStorageRoot = ""//https://files.lernspiel.codefury.ch/e48fbf1c-5353-40c9-aaa4-a140c030ceb6";
          singletons.phaserGame.listNativeFiles = appState.listNativeFiles;
        }

        let nativeStorageRoot = (Capacitor.isNativePlatform() ? ((await Filesystem.getUri({
          directory:Directory.Library,
          path:"/"
        })).uri||"").replace(/\/$/,"") : null);

        // eslint-disable-next-line no-undef
        this.scenes = scenes;
        if (singletons.phaserGame && appState.isConfigCompatible({useCanvas,supportRetina})) {
          let oldScenes = Object.keys(singletons.phaserGame.scene.keys);
          oldScenes.forEach(key=>{
            this.handleRelease();
            singletons.phaserGame.scene.remove(key);
          });
          setArgs({nativeStorageRoot});
          /* @ crashes phaser@beta.18 - no it doesn't.. and it's needed anyway */
          singletons.phaserGame.cache.destroy();
          singletons.phaserGame.cache = new Phaser.Cache.CacheManager(singletons.phaserGame);

          for (let key in mapScenes) {
            singletons.phaserGame.scene.add(key,mapScenes[key]);
          }
          singletons.phaserGame.scene.start("game")
        }
        else {
          let gameIcon = document.querySelector(`#home-menu>div[data-id='${this.gameId}'] img`);
          if (gameIcon)
            gameIcon.classList.add("animate-heartbeat");
          await new Promise(async resolve=>{
            if (singletons.phaserGame) {
              singletons.phaserGame.cache.destroy();
              singletons.phaserGame.registry.destroy();
              singletons.phaserGame.scene.scenes.map(scene=>{
                scene.scene.stop();
                scene.events.destroy();
              });
              setTimeout(()=>{
                singletons.phaserGame.scene.destroy();
                singletons.phaserGame.events.removeAllListeners();
                singletons.phaserGame.renderer.destroy();
                singletons.phaserGame.loop.destroy();
                document.querySelector("#phaser-container").innerHTML = "";
                resolve();
              });
            }
            else
              resolve();
          });
          singletons.phaserGame = new Phaser.Game(config);
          setArgs({nativeStorageRoot});
          if (gameIcon)
            gameIcon.classList.remove("animate-heartbeat");

          appState.setConfig({useCanvas,supportRetina})
        }
        singletons.phaserGame.lastRoute = appState.lastRoute;
        singletons.phaserGame.lastScrollTop = appState.mapLastScrollTop[this.$route.path]||0;
        appState.currentGame = this.game;
      }

      let handleResize = null;
      let debounceResize = 1000;
      if (singletons.onPhaserResize)
        window.removeEventListener("resize",singletons.onPhaserResize);
      singletons.onPhaserResize = (e) => {
        clearTimeout(handleResize);
        const {isOpen,keyboardHeight} = useKeyboard();
        let wasOpen = isOpen.value;
        if (!initFullScreen) {
          handleResize = setTimeout(()=>{
            if (!supportResize) {
              if (wasOpen==isOpen.value && !this.chatModalWasRecentlyClosed)
                setup()
            }
            else {
              if (singletons.phaserGame && singletons.phaserGame.events)
                singletons.phaserGame.events.emit("onResize");
            }
          },debounceResize);
        }
        else if (supportFullscreen && singletons.phaserGame && singletons.phaserGame.events)
          singletons.phaserGame.events.emit("onResize");
      }
      window.addEventListener("resize",singletons.onPhaserResize)
      setup();
    });
  }
});
</script>

<style>
.hide {
  opacity:0;
  transition:all 0.3s ease-out;
}
.menu-btn {
  color:#ffffff;
  text-shadow: rgba(5, 150, 105, 0) 0 0 5px;
  transition: all 0.3s ease-out;
}
.menu-btn:hover {
  text-shadow: rgba(5, 150, 105, 1) 0 0 5px;
}
img.mm-icon {
  transition:all 0.3s ease-out;
  filter:drop-shadow(0px 0px 8px #df7126);
  image-rendering: crisp-edges;
  min-height: 128px;
}
img.mm-icon:hover {
  filter:drop-shadow(0px 0px 8px #fff)
}
#phaser-container>div {
  z-index: 100;
}

.category-h {
  opacity: 1;
  top: 0;
  transition: top 0.4s ease-out, opacity 0.4s ease-out;
}
.category-h.h-load.load-1 {
  opacity: 0;
  top: -2rem;
}
.category-h.h-load.load-2 {
  opacity: 0;
  top: 2rem;
}
.category-v {
  opacity: 1;
  left: 0;
  transition: left 0.4s ease-out, opacity 0.4s ease-out;
}
.category-v.v-load.load-1 {
  opacity: 0;
  left: -2rem;
}
.category-v.v-load.load-2 {
  opacity: 0;
  left: 2rem;
}
</style>