
import {
  alertController,
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  menuController
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {gameControllerOutline, peopleOutline, settingsOutline, cloudDownloadOutline} from 'ionicons/icons';
import socket from "./socket.js"
import {Storage} from "@ionic/storage";
import {socketData} from "./data/socketdata.js";
import {getGame} from "./data/games.js";
import {singletons} from "./data/singletons.js"
import IngameFab from "@/components/IngameFab.vue";
import {appState} from "./data/reactive.js"
import {Capacitor} from "@capacitor/core";
import {buildNativeFilesList, checkForUpdates} from "./utils.js"
import {connectSocket} from "./socket.js";
import {SplashScreen} from "@capacitor/splash-screen";

export default defineComponent({
  name: 'App',
  props:{
    keycloak:Object
  },
  components: {
    IngameFab,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet
  },
  data() {
    return {
      data : {
        users:[],
        messages:{},
        phaserGame:null
      },
      phaserContainerClass:"top-container",
      routerOutletClass:"",
      keycloakRef:0
    }
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [{
      title: 'Spiele',
      url: '/games',
      iosIcon: gameControllerOutline,
      mdIcon: gameControllerOutline
    },{
      title: 'Freunde',
      url: '/friends',
      iosIcon: peopleOutline,
      mdIcon: peopleOutline
    },{
      title: 'Einstellungen',
      url: '/settings',
      iosIcon: settingsOutline,
      mdIcon: settingsOutline
    },...Capacitor.isNativePlatform() ? [{
      title: 'Neue Inhalte verfügbar!',
      url: '/update',
      iosIcon: cloudDownloadOutline,
      mdIcon: cloudDownloadOutline
    }] : []];

    const path = window.location.pathname;
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.url === path.toLowerCase());
    }

    const route = useRoute();
    const router = useRouter();

    const currentGame = ref({});
    currentGame.value = {};

    return {
      selectedIndex,
      appPages,
      gameControllerOutline,
      settingsOutline,
      peopleOutline,
      isSelected: (url) => url === route.path ? 'selected' : '',
      router,
      currentGame,
      appState
    }
  },
  watch: {
    $route(to,from) {
      this.setTopContainer(to.path)
    }
  },
  async created() {
    this.setTopContainer(this.$route.path);
    SplashScreen.hide();
    buildNativeFilesList();

    socketData.data = this.data;

    const sessionID = localStorage.getItem("sessionID");
    const store = new Storage();
    store.create().then(async ()=>{
      store.get("settings").then((data)=>{
        data=data||{};
        appState.testMode = data.testMode;
        appState.adultHumor = !Capacitor.isNativePlatform() && data.adultHumor;
        appState.useWebsockets = data.useWebsockets;

        if (appState.useWebsockets) {
          let username = data.username;
          connectSocket({sessionID,username,accessToken:this.$props.keycloak.idToken})
        }
      });
      /*let storageKeys = [1,2,3].map(n=>`avatar0${n}_global`);
      let data = {};
      for (const key of storageKeys) {
        data[key] = await store.get(key);
      }
      socket.emit("send-storage",{data,accessToken:this.$props.keycloak.idToken});*/
    });
    socket.on("receive-storage",async (data)=>{
      const store = new Storage();
      await store.create();
      for (let key in data) {
        await store.set(key,data[key]);
      }

      // redraw current gameScene (most likely MainMenu or CategoryMenu)
      let gameScene = singletons.phaserGame.scene.getScene("game");
      if (gameScene) {
        setTimeout(()=>{
          gameScene.scene.restart();
        },500);
      }
      this.keycloakRef++;
    });
    this.$props.keycloak.onAuthLogout = ()=>{
      this.keycloakRef++;
    }

    socket.on("connect", () => {
      this.data.users.forEach((user) => {
        if (user.self) {
          user.connected = true;
        }
      });
    });
    socket.on("disconnect", () => {
      this.data.users.forEach((user) => {
        if (user.self) {
          user.connected = false;
        }
      });
    });
    socket.on("users", (users) => {
      users.forEach((user) => {
        for (let i = 0; i < this.data.users.length; i++) {
          const existingUser = this.data.users[i];
          if (existingUser.userID === user.userID) {
            existingUser.connected = user.connected;
            return;
          }
        }
        user.self = user.userID === socket.userID;
        this.data.users.push(user);
      });
      // put the current user first, and sort by username
      users.sort((a, b) => {
        if (a.self) return -1;
        if (b.self) return 1;
        if (a.username < b.username) return -1;
        return a.username > b.username ? 1 : 0;
      });
      this.data.users = users;
    });
    socket.on("user connected", (user) => {
      for (let i = 0; i < this.data.users.length; i++) {
        const existingUser = this.data.users[i];
        if (existingUser.userID === user.userID) {
          existingUser.username = user.username;
          existingUser.connected = true;
          return;
        }
      }
      this.data.users.push(user);
    });
    socket.on("user disconnected", (id) => {
      for (let i = 0; i < this.data.users.length; i++) {
        const user = this.data.users[i];
        if (user.userID === id) {
          user.connected = false;
          break;
        }
      }
    });
    socket.on("receive-message", ({ content, from, to, lastchange }) => {
      let rel = from==socket.userID ? to : from;
      this.data.messages[rel] = this.data.messages[rel] || [];
      this.data.messages[rel].push({id:this.data.messages[rel].length+1,content,to,lastchange});
      if (singletons.phaserGame && singletons.phaserGame.events) {
        let user = this.getUserById(from);
        if (user)
          singletons.phaserGame.events.emit("onReceiveMessage",{ content, from:user });
      }
    });
    let getUserById = (userId) => {
      for (let user of this.data.users) {
        if (user.userID==userId)
          return user;
      }
      return null;
    }
    socket.on("receive-invite", async ({ gameId, from, gameSession }) => {
      let fromUser = getUserById(from);
      let gameDescription = (getGame(gameId)||{}).description;
      const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: gameDescription,
        message: (fromUser ? fromUser.username : from)+" möchte dich zu einer Partie "+gameDescription+" einladen. Annehmen?",
        buttons: [
          {
            text: 'Nein',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              socket.emit("send-decline",{userId:from,gameId});
            },
          },
          {
            text: 'Ja',
            handler: () => {
              socket.emit("send-accept",{userId:from,gameId});
              this.router.replace("/games/"+gameId+"/"+gameSession);
              singletons.phaserGame.events.emit("onRestartRequested");
            },
          },
        ],
      });
      alert.present();
    });
  },
  destroyed() {
    socket.off("connect_error");
    socket.off("connect");
    socket.off("disconnect");
    socket.off("users");
    socket.off("user connected");
    socket.off("user disconnected");
    socket.off("receive-message");
    socket.off("receive-invite");
  },
  methods:{
    handleMenuDidClose:function(){
      if (singletons.phaserGame && singletons.phaserGame)
        singletons.phaserGame.events.emit("onMenuDidClose");
    },
    handleMenuWillOpen:function(){
      if (singletons.phaserGame && singletons.phaserGame) {
        singletons.phaserGame.events.emit("onMenuWillOpen");
        if (singletons.phaserGame.id)
          this.currentGame = {
            ...getGame(singletons.phaserGame.id),
            session:singletons.phaserGame.session||"local"
          };
      }
    },
    closeMenu:function(e) {
      menuController.close();
      e.stopPropagation();
    },
    getUserById(userID) {
      let users = this.data.users.filter(user=>user.userID==userID);
      return users.length ? users[0] : null;
    },
    setTopContainer(path) {
      if (path.match(/^\/games/) || path.match(/^\/avatar/)) {
        this.routerOutletClass = "";
        this.phaserContainerClass = "top-container";
      }
      else {
        this.routerOutletClass = "top-container";
        this.phaserContainerClass = "";
      }
    },
    filterAccessible:pages=>{
      let filteredPages = pages;
      if (!appState.useWebsockets)
        filteredPages = filteredPages.filter(page=>page.url!=="/friends");
      if (!appState.hasUpdate)
        filteredPages = filteredPages.filter(page=>page.url!=="/update");
      return filteredPages;
    }
  },
});
