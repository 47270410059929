const categories = {
  lesen:{
    name: 'Lesen',
    icon:"mm-buchstaben.png",
    gradientDef:{topleft:"#df7126",topright:"#df7126",bottomleft:"#37946e",bottomright:"#053426"},
    preloadColor:"#df7126"
  },
  rechnen:{
    name: 'Rechnen',
    icon:"mm-rechnen-1.png",
    gradientDef:{topleft:"#37946e",topright:"#37946e",bottomleft:"#d21c05",bottomright:"#df7126"},
    preloadColor:"#37946e"
  },
  wissen:{
    name: 'Wissen',
    icon:"mm-zeit-1.png",
    gradientDef:{topleft:"#663931",topright:"#37946e",bottomleft:"#df7126",bottomright:"#d21c05"},
    preloadColor:"#663931"
  },
  spielen:{
    name: 'Spielen',
    icon: 'mm-puzzle.png',
    gradientDef:{topleft:"#37946e",topright:"#df7126",bottomleft:"#37946e",bottomright:"#df7126"},
    preloadColor:"#37946e"
  }
};

export const getCategories = () => {return categories;}
export const getCategory = (label) => {return categories[label];}