import { io } from "socket.io-client";
import {Capacitor} from "@capacitor/core";
import {commBaseUrl} from "@/config";

const URL = commBaseUrl;//Capacitor.isNativePlatform() ? commBaseUrl : `${window.location.origin}`;
//const URL = "http://localhost:3000";
const socket = io(URL, { autoConnect: false });

socket.onAny((event, ...args) => {
});

export function connectSocket({sessionID,username,accessToken}) {
    socket.auth = { sessionID, username };
    socket.connect();
    socket.on("session", ({ sessionID, userID }) => {
        console.log("session",sessionID,userID);
        socket.auth = { sessionID };
        localStorage.setItem("sessionID", sessionID);
        socket.userID = userID;
        socket.emit("request-storage",{accessToken});
    });
    socket.on("connect_error", (err) => {
        if (err.message == "invalid sessionID") {
            socket.auth = { username };
            socket.connect();
        }
    });
}
export function disconnectSocket() {
    socket.disconnect();
}

export default socket;
