
import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonMenuButton, IonButtons, IonList, IonListHeader, IonInput, IonLabel, IonBackButton, IonTextarea, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonButton } from '@ionic/vue';
import { defineComponent,ref } from 'vue';
import socket from "../socket";
import {socketData} from "../data/socketdata"
import {useRoute, useRouter} from "vue-router";
import {sendOutline,closeOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'Friend',
  props: {
    userId:String,
    handleCloseModal:Object
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    //const userId = route.params.id;
    return { router, route, closeOutline }
  },
  mounted() {
    setTimeout(()=>this.$refs.textArea.$el.setFocus(),500);
  },
  data() {
    return {
      sendOutline,
      messages:[],
      sendText:""
    }
  },
  computed: {
    countLiveMessages:function() {
      return socketData.data.messages[this.userId] && socketData.data.messages[this.userId].length || 0
    },
    userIx:function() {
      let i=0;
      while (i<this.socketData.data.users.length && this.socketData.data.users[i].userID!=this.userId)
        i++;
      return i<this.socketData.data.users.length ? i : false;
    },
    username:function() {
      return this.userIx!==false ? this.socketData.data.users[this.userIx].username : "";
    }
  },
  watch: {
    countLiveMessages:function(val) {
      setTimeout(()=>{this.scrollToBottom()});
    }
  },
  ionViewWillEnter() {

  },
  created() {
    this.socketData = socketData;
    socket.emit("request-messages",{otherId:this.userId});
    socket.on("receive-messages",(messages=>{
      this.messages = messages;
      let liveMessages = this.socketData.data.messages[this.userId] || [];
      liveMessages.splice(0,liveMessages.length);
      setTimeout(()=>{this.scrollToBottom()});
    }));
  },
  destroyed() {

  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonTextarea, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonButton
  },
  methods: {
    refresh: (ev) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
    filterConnected: users=>users.filter(user=>user.connected),
    filterNonEmpty:messages=>messages.filter(message=>message.content.length),
    sendMessage: function() {
      socket.emit("send-message", {
        content:this.sendText,
        to: this.userId,
      });
      this.sendText="";
      this.$refs.textArea.$el.setFocus();
    },
    getMessageClass(message) {
      return "message "+(message.to==this.userId ? 'message-mine' : 'message-other');
    },
    scrollToBottom() {
      let container = this.$el.querySelector("#messages");
      container.scrollTop = container.scrollHeight;
    },
    keyup(e) {
      if (e.code=="Enter" && !e.shiftKey)
        this.sendMessage();
    }
  },
});
