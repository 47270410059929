
import { IonContent, IonHeader, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonMenuButton, IonButtons } from '@ionic/vue';
import { defineComponent } from 'vue';
import { getGames } from '@/data/games';
import GameListItem from "@/components/GameListItem.vue";
import {ScreenOrientation} from "@ionic-native/screen-orientation";

export default defineComponent({
  name: 'Home',
  data() {
    return {
      games: getGames()
    }
  },
  ionViewWillEnter() {
    try {
      ScreenOrientation.unlock();
    } catch (err) {}
  },
  methods: {
    refresh: (ev) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    GameListItem
  },
});
