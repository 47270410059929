import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home';
import Settings from '../views/Settings';
import Friends from "../views/Friends";
import Friend from "../views/Friend";
import Games from "../views/Games";
import Categories from "@/views/Categories";
import Update from "../views/Update";

const routes = [
  {
    path: '/',
    redirect: '/games'
  },
  {
    path: '/games',
    component: Games,
    meta:{
      hasTutorial:true
    }
  },
  {
    path: '/games/:category',
    component: Games
  },
  {
    path: '/avatar',
    component: Games
  },
  {
    path: '/games/:id/:session',
    component: Games
  },
  {
    path: '/friends',
    name: 'Freunde',
    component: Friends
  },
  {
    path: '/friends/:userId',
    component: Friend,
    props:true
  },
  {
    path: '/settings',
    name: 'Einstellungen',
    component: Settings,
    meta:{
      hasTutorial:true
    }
  },
  {
    path: '/update',
    name: 'Update',
    component: Update,
  },
  {
    path: '/update/:run',
    name: 'RunUpdate',
    component: Update,
    props:true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
