import {Storage} from "@ionic/storage";
import socket from "@/socket";
const AVATARS_KEY = "avatars";
const originalAvatars = {
  avatar01:{
    name: 'Eliah',
    file:"character01.png",
    backdropColor:"#639bff",
    origin:{x:0.48,y:0.23},
    dim:{x:24, y:24},
    padding:{x:12,y:12},
    backdropLabel:"avatar-backdrop-1",
    maskLabel:"avatar-mask-1",
    scale:1
  },
  avatar02:{
    name: 'Nusha',
    file:"character02.png",
    backdropColor:"#e577f5",
    origin:{x:0.49,y:0.3325},
    dim:{x:24, y:24},
    padding:{x:12,y:12},
    backdropLabel:"avatar-backdrop-1",
    maskLabel:"avatar-mask-1",
    scale:1
  },
  avatar03:{
    name: 'Niobe',
    file:"character03.png",
    backdropColor:"#fd63b0",
    origin:{x:0.5,y:0.35},
    dim:{x:24, y:24},
    padding:{x:12,y:12},
    backdropLabel:"avatar-backdrop-1",
    maskLabel:"avatar-mask-1"
  },
  pirate01:{
    file:"pirate01.png",
    origin:{x:0.48,y:0.23},
    dim:{x:24, y:24},
    padding:{x:12,y:12},
    backdropLabel:"avatar-backdrop-1",
    maskLabel:"avatar-mask-1",
    scale:1
  }
};
const defaultAvatars = ["avatar01","avatar02","avatar03"]
const initAvatars = async () => {
  let store = new Storage();
  await store.create();
  let avatars = {};
  defaultAvatars.forEach(key=>{avatars[key]=originalAvatars[key];});
  await store.set(AVATARS_KEY,avatars)
}
export const getOriginalAvatarsMap = () => {
  return originalAvatars;
}
export const getOriginalAvatars = () => {
  let list = [];
  for (let label in originalAvatars) {
    if (originalAvatars.hasOwnProperty(label))
      list.push({...originalAvatars[label],label})
  }
  return list;
}
export const getAvatarsMap = async () => {
  let store = new Storage();
  await store.create();
  return new Promise(resolve=>{
    store.get(AVATARS_KEY).then((avatars)=>{
      if (!avatars)
        initAvatars().then(()=>store.get(AVATARS_KEY).then((avatars)=>resolve(avatars)));
      else resolve(avatars);
    });
  });
}
export const getAvatarsList = async () => {
  let map = await getAvatarsMap();
  let list = [];
  for (let label in map) {
    if (map.hasOwnProperty(label))
      list.push({...map[label],label})
  }
  return new Promise(resolve=>resolve(list));
}
export const setCurrentAvatar = async (label) => {
  let store = new Storage();
  await store.create();
  await store.set("selectedAvatar",label);
}
export const getCurrentAvatar = async () => {
  let map = await getAvatarsMap();
  let store = new Storage();
  await store.create();
  let label = await store.get("selectedAvatar") || false;
  return new Promise(resolve=>{
    if (label===false)
      label = "avatar01";
    resolve({...map[label],label});
  });
}
export const saveAvatar = async (label,data) => {
  let avatars = await getAvatarsMap();
  let avatar = {...data};
  delete avatar["label"];
  avatars[label] = {...avatars[label],...avatar};
  let store = new Storage();
  await store.create();
  await store.set(AVATARS_KEY,avatars);
  console.log("save avatar",label,data);
  socket.emit("send-storage",{data:{[AVATARS_KEY]:avatars}})
}