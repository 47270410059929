import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "triangle"
}
const _hoisted_2 = { class: "textcontent" }
const _hoisted_3 = { class: "time-label" }
const _hoisted_4 = {
  key: 1,
  class: "triangle"
}
const _hoisted_5 = {
  key: 0,
  class: "triangle"
}
const _hoisted_6 = { class: "textcontent" }
const _hoisted_7 = { class: "time-label" }
const _hoisted_8 = {
  key: 1,
  class: "triangle"
}
const _hoisted_9 = { style: {"flex-grow":"0","display":"flex","align-items":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.username), 1)
                ]),
                _: 1
              }),
              (_ctx.handleCloseModal)
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.handleCloseModal }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            ios: _ctx.closeOutline,
                            md: _ctx.closeOutline
                          }, null, 8, ["ios", "md"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.userId), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, { style: {"display":"flex","flex-flow":"column","height":"100%"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, {
                style: {"flex-grow":"1","height":"0","overflow":"auto"},
                id: "messages"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterNonEmpty(_ctx.messages), (message) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: message.id,
                          class: _normalizeClass(_ctx.getMessageClass(message))
                        }, [
                          (message.to!=_ctx.userId)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", null, _toDisplayString(message.content), 1),
                            _createElementVNode("div", _hoisted_3, _toDisplayString(message.lastchange), 1)
                          ]),
                          (message.to==_ctx.userId)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                            : _createCommentVNode("", true)
                        ], 2))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterNonEmpty(_ctx.socketData.data.messages[_ctx.userId]||[]), (message) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: message.id,
                          class: _normalizeClass(_ctx.getMessageClass(message))
                        }, [
                          (message.to!=_ctx.userId)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", null, _toDisplayString(message.content), 1),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(message.lastchange), 1)
                          ]),
                          (message.to==_ctx.userId)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                            : _createCommentVNode("", true)
                        ], 2))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    style: {"display":"flex","flex-direction":"row"},
                    id: "content-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, { style: {"flex-grow":"1"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            ref: "textArea",
                            placeholder: 'Nachricht an '+_ctx.username,
                            modelValue: _ctx.sendText,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendText) = $event)),
                            onKeyup: _ctx.keyup
                          }, null, 8, ["placeholder", "modelValue", "onKeyup"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.sendText.length)
                          ? (_openBlock(), _createBlock(_component_ion_button, {
                              key: 0,
                              fill: "clear",
                              onClick: _ctx.sendMessage
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.sendOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}