import {Directory, Filesystem} from "@capacitor/filesystem";
import {Capacitor} from "@capacitor/core";
import {appState} from "@/data/reactive";
import {version} from "../package.json"
import {filesBaseUrl} from "@/config";
import {Storage} from "@ionic/storage";

const UPDATE_MIN_INTERVAL=300*1000;//86400000;
export function getBounds(verts){
    let min = {x: Infinity, y: Infinity}, max = {x: -Infinity, y: -Infinity};
    for (let i=0;i<verts.length;i++) {
        if (verts[i].x<min.x)
            min.x = verts[i].x;
        if (verts[i].x>max.x)
            max.x = verts[i].x;
        if (verts[i].y<min.y)
            min.y = verts[i].y;
        if (verts[i].y>max.y)
            max.y = verts[i].y;
    }
    return {min,max}
}
export function getCenter(verts) {
    let {min,max} = getBounds(verts);
    return {x:(max.x+min.x)/2, y:(max.y+min.y)/2};
}
export function getDim(verts) {
    let {min,max} = getBounds(verts);
    return {width:max.x-min.x, height:max.y-min.y};
}
export function centeredGeometry(verts) {
    let mid = getCenter(verts);
    let out = [];
    verts.forEach(v=>{
        out.push({x:v.x-mid.x,y:v.y-mid.y});
    });
    return out;
}
export async function nativeFileExists({path,directory}) {
    try {
        let stat = await Filesystem.stat({path,directory});
        return true;
    }
    catch (err) {
        return false;
    }
}
export async function getNativeFiles({path,directory}) {
    let list = [];
    let stack = [{path,type:"directory"}];
    while (stack.length) {
        let cur = stack.pop();
        if (cur.type==="directory") {
            try {
                let {files} = await Filesystem.readdir({path:cur.path,directory});
                files = files.map(file=>({...file,path:`${cur.path}/${file.name}`.normalize()}));
                stack.unshift(...files);
            } catch (err) {}
        }
        else
            list.push(cur)
    }
    return list.map(item=>item.path);
}
export const nativeFilesRoot={path:"/assets",directory:Directory.Library}
export const updateBaseUrl=`${filesBaseUrl}/${version}`;
export async function getUpdatesList() {
    let updates = [];
    try {
        let response = await fetch(`${updateBaseUrl}/updates.json`, {cache: "reload"});
        updates = (await response.json()).filter(({prefix})=>prefix!=="_base");
    } catch (err) {}
    return updates;
}
export async function checkForUpdates() {
    const store = new Storage();
    await store.create();
    let lastUpdateCheck = await store.get("lastUpdateCheck");
    console.log("lastUpdateCheck",lastUpdateCheck);
    if (!lastUpdateCheck) {
        await store.set("lastUpdateCheck",new Date().getTime());
    }
    else if (new Date().getTime() - lastUpdateCheck>UPDATE_MIN_INTERVAL) {
        await store.set("lastUpdateCheck",new Date().getTime());
        let listNativeFiles = await getNativeFiles(nativeFilesRoot);
        let listUpdates = await getUpdatesList();
        for (const {prefix,n} of listUpdates) {
            let i=0;
            while (i<n && listNativeFiles.indexOf(`/assets/${prefix}_${i}.bin`)>-1)
                i++;
            if (i<n) {
                appState.hasUpdate = true;
                return {prefix};
            }
        }
    }
    appState.hasUpdate = false;
    return false;
}
export async function buildNativeFilesList() {
    if (Capacitor.isNativePlatform()) {
        if (!await nativeFileExists(nativeFilesRoot))
            await Filesystem.mkdir(nativeFilesRoot)
        appState.listNativeFiles = await getNativeFiles(nativeFilesRoot);
    }
}