import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './styles/tailwind.css';
import Keycloak from "keycloak-js";
import {App as CapacitorApp, URLOpenListenerEvent} from "@capacitor/app";
import {singletons} from "@/data/singletons";
import socket from "@/socket";

let initOptions = {
  url: 'https://auth.codefury.ch', realm: 'realm01', clientId: 'myclient02'/*, onLoad: 'login-required'*/
}
const keycloak = new Keycloak(initOptions);
let onSuccess = ()=>{
  socket.emit("request-storage",{accessToken:keycloak.idToken});
}
keycloak.onAuthSuccess = onSuccess;
keycloak.onAuthLogout = onSuccess;
keycloak.onAuthRefreshSuccess = onSuccess;
console.log("before keycloak init");
(async function() {
  try {
    await keycloak.init({ onLoad:"check-sso"/*,
      adapter: 'cordova',
      responseMode: 'query',
      redirectUri: 'http://localhost'*/
    });
  } catch (err) { console.log(err); }
  console.log("after keycloak init")
  const app = createApp(App,{keycloak})
    .use(IonicVue, {animated:false})
    .use(router);
  console.log("app created")
  router.isReady().then(() => {
    console.log("router ready");
    app.mount('#app');
    console.log("app mounted")
  });
})();