const games = [
    {
        name: 'Test',
        topic: 'Test',
        description: "Test",
        id: 0
    },
    {
        name: 'Deutsch',
        topic: 'Buchstaben 1',
        description: "Wörter bilden",
        id: 1,
        icon: "mm-buchstaben.png",
        category: "lesen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Zahlen 1',
        description: "Zählen",
        icon: "mm-rechnen-1.png",
        id: 12,
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Zahlen 2',
        description: "Einerreihe",
        icon: "mm-rechnen-15.png",
        id: 34,
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Zahlen 3',
        description: "Zahlenreihen",
        icon: "mm-rechnen-4.png",
        id: 14,
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Zahlen 4',
        description: "Zahlen schreiben",
        icon: "mm-rechnen-14.png",
        id: 35,
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 1',
        description: 'Gleichungen',
        id: 20,
        icon: "mm-rechnen-2.png",
        category: "rechnen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 2',
        description: 'Pyramide',
        id: 21,
        icon: "mm-rechnen-5.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 3',
        description: 'Trio-Pyramide',
        id: 22,
        icon: "mm-rechnen-6.png",
        category: "rechnen",
        fab: {
            settings: true,
            reload: true,
            help: true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 4',
        description: 'Vorzeichen-Pyramide',
        id: 24,
        icon: "mm-rechnen-7.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 5',
        description: 'Waben',
        id: 23,
        icon: "mm-rechnen-8.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 6',
        description: 'Kreuz',
        id: 25,
        icon: "mm-rechnen-9.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 7',
        description: 'Kürzester Weg',
        id: 26,
        icon: "mm-rechnen-10.png",
        category: "rechnen",
        fab: {
            settings: true,
            reload: true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Rechnen 8',
        description: 'Zahlen-Spirale',
        id: 27,
        icon: "mm-rechnen-12.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Multiplikation 1',
        description: 'Gleichungen',
        id: 36,
        icon: "mm-rechnen-2.png",
        category: "rechnen",
        fab: {
            settings:true,
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Strukturen 1',
        description: 'Kopieren',
        id: 28,
        icon: "mm-rechnen-13.png",
        category: "rechnen",
        fab: {
            settings: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Strukturen 2',
        description: 'Sortieren',
        id: 29,
        icon: "mm-rechnen-1.png",
        category: "rechnen",
        fab: {
            settings: true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Mathematik',
        topic: 'Strukturen 3',
        description: 'Zahlen-Waage',
        id: 30,
        icon: "mm-rechnen-11.png",
        category: "rechnen",
        fab: {
            settings: true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    /*{
        name: 'Mathematik',
        topic: 'Test',
        description: 'Test',
        id: 31,
        icon: "mm-rechnen-1.png",
        category: "rechnen",
        fab: {
            settings: true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },*/
    {
        name: 'MNG',
        topic: 'Zeit 1',
        description: 'Uhr richtig stellen',
        icon: "mm-zeit-1.png",
        id: 3,
        category: "wissen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Deutsch',
        topic: 'Buchstaben 2',
        description: 'Anfangsbuchstaben zuordnen',
        icon: "mm-buchstaben-2.png",
        id: 4,
        category: "lesen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'MNG',
        topic: 'Buchstaben 3',
        description: 'Kategorien bilden',
        icon: "mm-buchstaben-3.png",
        id: 5,
        category: "lesen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'MNG',
        topic: 'Puzzle',
        description: "Puzzle",
        id: 7,
        icon: 'mm-puzzle.png',
        category: "spielen",
        fab: {
            settings: true,
            reload: true,
            network: true
        }
    },
    {
        name: 'MNG',
        topic: 'Ausmalen',
        description: "Ausmalen",
        id: 8,
        icon: 'mm-malen.png',
        category: "spielen"
        /*fab:{
          settings:true,
          screenshot:true
        }*/
    },
    {
        name: 'MNG',
        topic: 'Zeit 2',
        description: "Zeit ablesen",
        icon: "mm-zeit-2.png",
        id: 9,
        category: "wissen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'MNG',
        topic: 'Zeit 3',
        description: "Digital-Uhr",
        icon: "mm-zeit-3.png",
        id: 10,
        category: "wissen",
        fab: {
            reload:true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    {
        name: 'Deutsch',
        topic: 'Sätze 1',
        description: "Sätze bilden",
        icon: "mm-buchstaben-4.png",
        id: 11,
        category: "lesen",
        fab:{
            settings:true,
            reload: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    },
    /*{
        name: 'MNG',
        topic: 'Reihenfolge 1',
        description: "Abfolgen bilden",
        icon: "mm-rechnen-3.png",
        id: 13,
        category: "wissen"
    },*/
    {
        name: 'MNG',
        topic: 'Labyrinth',
        description: "Labyrinth",
        id: 15,
        fab: {
            settings: true,
            network: true,
            reload: true
        },
        icon: "mm-labyrinth.png",
        category: "spielen"
    },
    {
        name: 'MNG',
        topic: 'Memory',
        description: "Memory",
        id: 16,
        fab: {
            settings: true,
            network: true,
            reload: true,
            chat: true
        },
        icon: "mm-memory-1.png",
        category: "spielen"
    },
    {
        name: 'MNG',
        topic: 'Sudoku',
        description: "Sudoku",
        icon: "mm-sudoku.png",
        id: 17,
        category: "spielen"
    },
    {
        name: 'MNG',
        topic: 'Test',
        description: "3d",
        id: 18,
        fab: {
            reload: true,
        },
    },
    {
        name: 'Deutsch',
        topic: 'Reime',
        description: 'Wörter zuordnen',
        icon: "mm-rhymes01.png",
        id: 19,
        category: "lesen",
        fab: {
            reload: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        },
    },
    {
        name: 'Deutsch',
        topic: 'Schreiben',
        description: 'Schreiben',
        id: 33,
        icon: "mm-buchstaben-5.png",
        category: "lesen",
        fab: {
            reload: true,
            help:true,
            horizontal: "start",
            vertical: "bottom",
            side: "top"
        }
    }
];

export const getGames = (category) => {
    return category ? games.filter(game => game.category == category) : games
}
export const getGame = (id) => games.find(m => m.id === id);
export const getCategories = () => {
    let map = new Map();
    games.forEach(game => {
        if (game.category)
            map.set(game.category, true);
    })
    return Array.from(map.keys());
}