<template>
  <ion-page>
    <ion-content :fullscreen="true" v-if="game || !route.params.id">
      <div id="phaser-container-1" class="absolute" style="left:0;right:0;bottom:0;top:0" ref="phaserContainer"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {getGame, getCategories} from "@/data/games";
import {getCategories as getCategoryMap} from "@/data/categories";
import { defineComponent, ref } from 'vue';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  menuController,
  IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,
  modalController,
  useKeyboard
} from "@ionic/vue";
import {ScreenOrientation} from "@ionic-native/screen-orientation";
import {Storage} from "@ionic/storage";
import socket from "../socket";
import * as Phaser from "phaser";
import {singletons} from "@/data/singletons";
import {
  add,
  caretDownOutline,
  buildOutline,
  wifiOutline,
  reloadOutline,
  ellipsisVerticalOutline,
  cameraOutline,
  chatboxOutline
} from 'ionicons/icons';

export default defineComponent({
  name: "Categories",
  components:{
    IonContent,
    IonPage,
  },
  setup() {
    try {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    } catch (err) {}
    const route = useRoute();
    const router = useRouter();
    const game = getGame(parseInt(route.params.id));
    const gameId = ref(null);
    gameId.value = 0;
    return { game, router, route,
      add,
      buildOutline,
      wifiOutline,
      reloadOutline,
      caretDownOutline,
      cameraOutline,
      ellipsisVerticalOutline,
      chatboxOutline,
      singletons,
      gameId
    }
  },
  data() {
    return {
      chatModalWasRecentlyClosed:false,
      handleChatModalClosed:null
    }
  },
  ionViewDidLeave() {
    window.removeEventListener("resize",singletons.onPhaserResize);
  },
  ionViewDidEnter() {
    const store = new Storage();
    let promises = [];
    let useCanvas = false;
    let supportRetina = false;
    let supportResize = false;
    let maxAspect = false;
    Promise.all([import("@/menu-main"), import("@/settings-avatar")]).then((modules)=> {
        let setup = ()=>{
          let Scenes = modules.map((module)=>module.default);
          let ww=window.innerWidth, wh=window.innerHeight;
          const config = {
            // eslint-disable-next-line no-undef
            type: useCanvas ? Phaser.CANVAS : Phaser.WEBGL,
            scene: Scenes,
            scale: {
              // eslint-disable-next-line no-undef
              mode: Phaser.Scale.NONE,
              // eslint-disable-next-line no-undef
              autoCenter: Phaser.Scale.CENTER_BOTH,
              parent: "phaser-container-1",
              width: (maxAspect && ww/wh>maxAspect ? maxAspect*window.innerHeight : window.innerWidth)*(supportRetina ? window.devicePixelRatio : 1),
              height: window.innerHeight*(supportRetina ? window.devicePixelRatio : 1),
              ...supportRetina ? {
                zoom:1.0/window.devicePixelRatio
              } : {}
            },
            physics: {
              default: 'matter',
              matter: {
                debug: false,
                gravity: {
                  y: 0.9
                },
              }
            },
            dom : {
              createContainer : true
            },
            mipmapFilter: "LINEAR_MIPMAP_LINEAR",
            transparent:true
          };

          // eslint-disable-next-line no-undef
          this.scenes = Scenes;
          new Promise((resolve,reject)=>{
            if (singletons.phaserGame) {
              singletons.phaserGame.registry.destroy();
              singletons.phaserGame.scene.scenes.forEach(scene=>{
                scene.events.destroy();
              });
              //singletons.phaserGame.renderer.destroy();
              singletons.phaserGame.destroy(true);
              let timeStart = new Date().getTime();
              let wait = () => {
                if (singletons.phaserGame.pendingDestroy && new Date().getTime()-timeStart<1000)
                  setTimeout(()=>wait());
                else {
                  if (this.$refs.phaserContainer)
                    this.$refs.phaserContainer.innerHTML = "";
                  resolve();
                }
              };
              wait();
            }
            else
              resolve();
          }).then(()=>{
            singletons.phaserGame = new Phaser.Game(config);
            singletons.phaserGame.router = this.router;
            singletons.phaserGame.route = this.route;
            singletons.phaserGame.id = this.gameId;
            singletons.phaserGame.listCategories = getCategories();
            singletons.phaserGame.mapCategories = getCategoryMap();
            singletons.phaserGame.socket = socket;
            singletons.phaserGame.onToggleMenu = ()=>{
              menuController.toggle();
            };
          });
        }
        let handleResize = null;
        let debounceResize = 1000;
        if (singletons.onPhaserResize)
          window.removeEventListener("resize",singletons.onPhaserResize);
        singletons.onPhaserResize = (e) => {
          clearTimeout(handleResize);
          const {isOpen,keyboardHeight} = useKeyboard();
          let wasOpen = isOpen.value;
          handleResize = setTimeout(()=>{
            if (!supportResize) {
              if (wasOpen==isOpen.value && !this.chatModalWasRecentlyClosed)
                setup()
            }
            else {
              if (singletons.phaserGame && singletons.phaserGame.events)
                singletons.phaserGame.events.emit("onResize");
            }
          },debounceResize);
        }
        window.addEventListener("resize",singletons.onPhaserResize)
        setup();
      });
  },

});
</script>

<style>
.hide {
  opacity:0;
  transition:all 0.3s ease-out;
}
.menu-btn {
  color:#ffffff;
  text-shadow: rgba(5, 150, 105, 0) 0 0 5px;
  transition: all 0.3s ease-out;
}
.menu-btn:hover {
  text-shadow: rgba(5, 150, 105, 1) 0 0 5px;
}
img.mm-icon {
  transition:all 0.3s ease-out;
  filter:drop-shadow(0px 0px 8px #df7126);
  image-rendering: crisp-edges;
}
img.mm-icon:hover {
  filter:drop-shadow(0px 0px 8px #fff)
}
#phaser-container-1>div {
  z-index: 100;
  font-family: "Pixel Operator";
}
.category-h {
  opacity: 1;
  top: 0;
  transition: top 0.4s ease-out, opacity 0.4s ease-out;
}
.category-h.h-load.load-1 {
  opacity: 0;
  top: -2rem;
  transition: top 0.4s ease-out;
}
.category-h.h-load.load-2 {
  opacity: 0;
  top: 2rem;
  transition: top 0.4s ease-out;
}
#phaser-container-1 {
  /*background-color: #df7126*/
  background-image: url("data:image/svg+xml;utf8,%3Csvg preserveAspectRatio='none' viewBox='0 0 1 1' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='g'%3E%3Cstop offset='0' stop-color='%23fff' stop-opacity='0'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='1'%3E%3C/stop%3E%3C/linearGradient%3E%3Cmask id='m'%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23g)'%3E%3C/rect%3E%3C/mask%3E%3ClinearGradient id='a' gradientTransform='rotate(90)'%3E%3Cstop offset='0' stop-color='%237d4949'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23df7126'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientTransform='rotate(90)'%3E%3Cstop offset='0' stop-color='%23df7126'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%237d4949'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23a)' mask='url(%23m)'%3E%3C/rect%3E%3Crect x='0' y='0' width='1' height='1' fill='url(%23b)' mask='url(%23m)' transform='translate(1,1) rotate(180)'%3E%3C/rect%3E%3C/svg%3E");
}
</style>