<template>
  <ion-page ref="page">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Lehrplan 21</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <p v-if="hasUpdates">Stand: Neue Inhate verfügbar</p>
      <img v-if="hasUpdates" :src="updateBaseUrl+'/collage.png'" alt="Neue Inhalte" @click="runImport">
      <p v-else>Stand: Inhalte aktuell</p>
      <ion-button v-show="hasUpdates" id="open-modal" @click="runImport">Update laden</ion-button>
      <ion-modal ref="modal" :backdrop-dismiss="false" :is-open="modalIsOpen" :presenting-element="presentingElement" @didPresent="importAssets">
        <ion-header>
          <ion-toolbar>
            <p></p>
            <ion-title>Update</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="abortImport()">Abbrechen</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <div class="flex flex-row justify-between">
            <div>Lade</div><div>{{(100*progress).toFixed(1)}}%</div>
          </div>
          <ion-progress-bar :value="progress"></ion-progress-bar>
          <ul class="text-xs">
            <li v-for="details of listDetails.slice(0,Math.min(10,listDetails.length))" :key="details">{{details}}</li>
          </ul>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonButton,
  IonModal,
  actionSheetController,
  IonProgressBar,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {Storage} from "@ionic/storage";
import {getCurrentAvatar,saveAvatar} from "@/data/avatars";
import {Capacitor} from "@capacitor/core";
import AssetsWorker from "../assets.bin.worker";
import {Directory, Encoding, Filesystem} from "@capacitor/filesystem";
import {
  buildNativeFilesList,
  checkForUpdates,
  getNativeFiles,
  getUpdatesList,
  nativeFilesRoot,
  updateBaseUrl
} from "../utils";

export default defineComponent({
  name: 'Update',
  data() {
    return {
      avatar: {},
      settings: {},
      presentingElement: undefined,
      progress: 0,
      listDetails:[],
      aborted:false,
      nativeFilesRoot:{path:"/assets",directory:Directory.Library},
      hasUpdates:false,
      modalIsOpen:false,
      assetsWorker:null
    }
  },
  setup() {
    return {updateBaseUrl}
  },
  props: {
    run:{
      default:0,
      type:Number
    }
  },
  mounted() {
    this.presentingElement = this.$refs.page.$el;
  },
  async created() {
    if (this.run)
      this.runImport();
    await this.checkForUpdates();
  },
  async ionViewWillEnter() {
    this.avatar = await getCurrentAvatar();
    const store = new Storage();
    await store.create();
    this.settings = (await store.get("settings")) || {};
  },
  methods: {
    async dismissModal(checkForUpdates=true) {
      this.modalIsOpen = false;
      if (checkForUpdates)
        await this.checkForUpdates();
      await buildNativeFilesList();
    },
    runImport() {
      this.modalIsOpen = true;
    },
    async abortImport() {
      this.aborted = true;
      if (this.assetsWorker)
        this.assetsWorker.postMessage({action:"stop"});
      this.assetsWorker.terminate();
      this.dismissModal(false);
    },
    async checkForUpdates() {
      this.hasUpdates = await checkForUpdates();
    },
    async importAssets() {
      if (Capacitor.isNativePlatform()) {
        let listUpdates = await getUpdatesList();
        let listNativeFiles = await getNativeFiles(nativeFilesRoot);
        this.aborted=false;
        for (const {prefix,n} of listUpdates) {
          this.progress=0;
          let i=0;
          while (i<n && !this.aborted) {
            let overallProgress=this.progress, localProgress=0;
            let file = `${prefix}_${i}.bin`;
            if (listNativeFiles.indexOf(`/assets/${file}`)===-1) {
              console.log(`downloading ${file}`);
              this.assetsWorker = new AssetsWorker();
              this.assetsWorker.postMessage({
                action:"run",
                baseUrl:updateBaseUrl,
                file
              });
              await new Promise(async resolve=>{
                this.assetsWorker.onmessage = async ({data}) => {
                  if (typeof data.downloadProgress!=="undefined") {
                    localProgress = data.downloadProgress;
                    this.progress = overallProgress + localProgress/n;
                    if (data.details)
                      this.listDetails.unshift(data.details);
                  }
                  else if (data.path && data.base64) {
                    const {path,base64} = data;
                    this.listDetails.unshift(`Schreibe: ${path}`);
                    await Filesystem.writeFile({
                      path:`/assets/${path}`,
                      directory:Directory.Library,
                      data:base64,
                      recursive:true,
                    })
                  }
                  else if (data.file && data.done) {
                    let {file} = data;
                    await Filesystem.writeFile({
                      path:`/assets/${file}`,
                      directory:Directory.Library,
                      recursive:true,
                      data:JSON.stringify({}),
                      encoding:Encoding.UTF8
                    });
                    resolve({});
                  }
                }
              });
            }
            i++;
            this.progress = i/n
          }
          if (this.aborted)
            break;
        }
        if (!this.aborted)
          this.dismissModal();
      }
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButton,
    IonModal,
    IonProgressBar,
  },
});
</script>