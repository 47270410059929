<template>
  <ion-fab :horizontal="getFabHorizontal()" :vertical="getFabVertical()" slot="fixed" v-if="game.fab" @click="handleToggleFab" class="m-2">
    <ion-fab-button>
      <ion-icon :ios="ellipsisVerticalOutline" :md="ellipsisVerticalOutline" />
    </ion-fab-button>
    <ion-fab-list :side="getFabSide()">
      <ion-fab-button color="light" @click="handleClickNetwork" v-if="game.fab.network">
        <ion-icon :ios="wifiOutline" :md="wifiOutline" />
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickSettings" v-if="game.fab.settings">
        <ion-icon :ios="buildOutline" :md="buildOutline"  />
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickHelp" v-if="game.fab.help" :class="'fab-button-help'">
        <ion-avatar>
          <img alt="help" src="/images/sprites/avatar-task.png" />
        </ion-avatar>
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickReload" v-if="game.fab.reload">
        <ion-icon :ios="reloadOutline" :md="reloadOutline"  />
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickScreenshot" v-if="game.fab.screenshot">
        <ion-icon :ios="cameraOutline" :md="cameraOutline"  />
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickChat" v-if="game.fab.chat">
        <ion-icon :ios="chatboxOutline" :md="chatboxOutline"  />
      </ion-fab-button>
      <ion-fab-button color="light" @click="handleClickAdminLog" v-if="singletons.phaserGame?.testMode">
        <ion-icon :ios="readerOutline" :md="readerOutline"  />
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  <div v-else-if="route.meta?.hasTutorial">
    <ion-fab horizontal="start" vertical="bottom" class="avatar-help">
      <a v-if="isMobileWeb" :href="['ios','osx'].indexOf(os)>-1 ? 'https://apps.apple.com/ch/app/lernspiel/id6448681047': 'https://play.google.com/store/apps/details?id=ch.codefury.lernspiel'">
        <img class="storebadge" :alt="['ios','osx'].indexOf(os)>-1 ? 'Jetzt im App Store' : 'Jetzt bei Google Play'" :src="['ios','osx'].indexOf(os)>-1 ? '/images/sprites/badge-appstore.png' : '/images/sprites/badge-playstore.png'"/>
      </a>
      <ion-chip @click="handleClickTutorial">
        <ion-avatar>
          <img alt="help" src="/images/sprites/avatar-help.png" />
        </ion-avatar>
        <ion-label>Hilfe</ion-label>
      </ion-chip>
    </ion-fab>
  </div>
</template>

<script>
import {singletons} from "@/data/singletons";
import {
  add,
  caretDownOutline,
  buildOutline,
  wifiOutline,
  reloadOutline,
  ellipsisVerticalOutline,
  cameraOutline,
  chatboxOutline,
  helpOutline,
  readerOutline
} from 'ionicons/icons';
import {
  IonFab,
  IonFabList,
  IonFabButton,
  IonIcon, IonLabel, IonChip, IonAvatar,
} from "@ionic/vue";
import {useRoute} from "vue-router";
import {Capacitor} from "@capacitor/core";
import platform from "platform";

export default {
  name: "IngameFab",
  components: {
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonLabel,
    IonChip,
    IonAvatar
  },
  props: {
    game: {
      type:Object,
      default:()=>({})
    }
  },
  setup() {
    const route = useRoute();
    let isWeb = Capacitor.getPlatform()==="web";
    let os = null;
    let isMobileWeb = false;
    if (isWeb) {
      os = platform.os.family.toLowerCase().replace(/\s+/g,"");
      isMobileWeb = isWeb && ["ios","osx","android"].indexOf(os)>-1;
    }
    console.log(isWeb,os,isMobileWeb);
    return {
      add,
      buildOutline,
      wifiOutline,
      reloadOutline,
      caretDownOutline,
      cameraOutline,
      ellipsisVerticalOutline,
      chatboxOutline,
      readerOutline,
      helpOutline,
      singletons,
      route,
      isMobileWeb,
      os
    }
  },
  data() {
    return {
      deferredKey:1
    }
  },
  methods: {
    getFabHorizontal() {
      return this.game && this.game.fab && this.game.fab.horizontal || "end";
    },
    getFabVertical() {
      return this.game && this.game.fab && this.game.fab.vertical || "top";
    },
    getFabSide() {
      return this.game && this.game.fab && this.game.fab.side || "bottom";
    },
    handleClickNetwork:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnNetwork");
    },
    handleClickSettings:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnSettings");
    },
    handleClickHelp:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnHelp");
    },
    handleClickReload:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnReload");
    },
    handleClickScreenshot:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnScreenshot");
    },
    handleClickChat:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnChat");
    },
    handleToggleFab:function(e) {
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onBtnFab");
    },
    handleChatModalDismiss:function() {
      if (singletons.phaserGame && singletons.phaserGame.events)
        singletons.phaserGame.events.emit("onChatModalDismiss");
    },
    handleClickTutorial:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events) {
        singletons.phaserGame.events.emit("onBtnTutorial");
      }
    },
    handleClickAdminLog:function(e){
      if (singletons.phaserGame && singletons.phaserGame.events) {
        singletons.phaserGame.events.emit("onBtnAdminLog");
      }
    },
  },
}
</script>

<style>
ion-fab-button.fab-button-in-list {
  width: 60px;
  height: 60px;
  transform: scale(1);
  transition: all 0.3s ease-out;
}
ion-fab-button.fab-button-in-list:hover {
  transform: scale(1.25);
}
ion-fab-button.fab-button-in-list > ion-icon {
  transform:scale(1.5)
}
ion-fab-button.fab-button-help {
  --background:green;
  --background-activated:green;
  --background-focused:green;
  --box-shadow:  0 0 10px lightgreen;
  --color: #ffffff;
}
ion-fab-button.fab-button-help::part(native) {
  background: green;
  color: #ffffff;
}
</style>