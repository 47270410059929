import {reactive} from "vue";
export const appState = reactive({
    currentGame: {},
    currentConfig: {
        useCanvas:false,
        supportRetina:true,
    },
    isConfigCompatible(config={}){
        return this.currentConfig.useCanvas===config.useCanvas && this.currentConfig.supportRetina===config.supportRetina;
    },
    setConfig({useCanvas,supportRetina}) {
        this.currentConfig.useCanvas = useCanvas;
        this.currentConfig.supportRetina = supportRetina;
    },
    lastRoute:{},
    mapLastScrollTop:{},
    testMode:false,
    listNativeFiles:[],
    hasUpdate:false
})