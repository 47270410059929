import {singletons} from "@/data/singletons";

export default new class AdminLog {
    async log(content,classes=false) {
        let items = singletons.adminLog || [];
        items.push({ content, classes, date:new Date().getTime() });
        singletons.adminLog = items;
    }
    async reset() {
        singletons.adminLog = [];
    }
    async getAll() {
        return singletons.adminLog || [];
    }
}