
import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonMenuButton, IonButtons, IonList, IonListHeader, IonItem, IonInput, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import socket from "../socket";
import {socketData} from "../data/socketdata"

export default defineComponent({
  name: 'Friends',
  data() {
    return {
      settings: {}
    }
  },
  ionViewWillEnter() {

  },
  methods: {
    refresh: (ev) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
    filterConnectedNotSelf: users=>users.filter(user=>user.connected&&!user.self),
  },
  created() {
    this.socketData = socketData;
    console.log(socketData);
  },
  destroyed() {

  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonList, IonListHeader, IonItem, IonLabel
  },
});
