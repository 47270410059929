<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Lehrplan 21</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-list lines="full" class="ion-no-margin">
        <ion-list-header lines="full">
          <ion-label>
            Einstellungen
          </ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label position="floating">Benutzername</ion-label>
          <ion-input v-model="avatar.name" @ionChange="onChangeUsername" :debounce="500"></ion-input>
        </ion-item>
        <ion-item v-if="(avatar.name||'').match(/^admin/i)">
          <ion-label>Test-Modus</ion-label>
          <ion-toggle :checked="settings.testMode" @ionChange="onChangeTestMode"></ion-toggle>
        </ion-item>
        <ion-item v-if="!Capacitor().isNativePlatform()">
          <ion-label>Erwachsenen-Humor</ion-label>
          <ion-toggle :checked="settings.adultHumor" @ionChange="onChangeAdultHumor"></ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label>Netzwerk-Funktion</ion-label>
          <ion-toggle :checked="settings.useWebsockets" @ionChange="onChangeUseWebsockets"></ion-toggle>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonMenuButton, IonButtons, IonList, IonListHeader, IonItem, IonInput, IonLabel, IonToggle } from '@ionic/vue';
import { defineComponent } from 'vue';
import {Storage} from "@ionic/storage";
import socket, {connectSocket, disconnectSocket} from "../socket";
import {getCurrentAvatar,saveAvatar} from "@/data/avatars";
import {appState} from "@/data/reactive";
import {Capacitor} from "@capacitor/core";

export default defineComponent({
  name: 'Settings',
  data() {
    return {
      avatar: {},
      settings: {}
    }
  },
  async ionViewWillEnter() {
    this.avatar = await getCurrentAvatar();
    const store = new Storage();
    await store.create();
    this.settings = (await store.get("settings")) || {};
    /* useWebsockets defaults to true on desktop version */
    if (!Capacitor.isNativePlatform() && typeof this.settings.useWebsockets==="undefined")
      this.settings.useWebsockets = true;
  },
  methods: {
    Capacitor() {
      return Capacitor
    },
    refresh: (ev) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
    onChangeUsername: async function(val) {
      let name = val.detail.value;
      await saveAvatar(this.avatar.label,{name});
      socket.emit("change-username",{username:name});
    },
    onChangeTestMode: async function(val) {
      let testMode = val.detail.checked;
      const store = new Storage();
      await store.create();
      store.set("settings",{...this.settings,testMode});
      appState.testMode = testMode;
    },
    onChangeAdultHumor: async function(val) {
      let adultHumor = val.detail.checked;
      const store = new Storage();
      await store.create();
      store.set("settings",{...this.settings,adultHumor});
      appState.adultHumor = adultHumor;
    },
    onChangeUseWebsockets: async function(val) {
      let useWebsockets = val.detail.checked;
      const store = new Storage();
      await store.create();
      store.set("settings",{...this.settings,useWebsockets});
      appState.useWebsockets = useWebsockets;
      if (useWebsockets) {
        await store.create();
        let settings = await store.get("settings");
        const sessionID = localStorage.getItem("sessionID");
        connectSocket({sessionID,username:settings.username});
      }
      else
        disconnectSocket();
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonList, IonListHeader, IonItem, IonInput, IonLabel, IonToggle
  },
});
</script>