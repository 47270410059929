
import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonMenuButton, IonButtons, IonList, IonListHeader, IonInput, IonLabel, IonBackButton, IonTextarea, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonButton } from '@ionic/vue';
import { defineComponent,ref } from 'vue';
import {useRoute, useRouter} from "vue-router";
import {sendOutline,closeOutline} from 'ionicons/icons';
import AdminLog from '../admin-log.js'

export default defineComponent({
  name: 'AdminLog',
  props: {
    handleCloseModal:{
      type:Function,
      default:()=>({})
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    //const userId = route.params.id;
    return { router, route, closeOutline }
  },
  data() {
    return {
      listMessages:[]
    }
  },
  async created() {
    this.listMessages = await AdminLog.getAll();
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonGrid, IonRow, IonCol, IonIcon, IonButton
  },
  methods: {
    refresh: (ev) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
    scrollToBottom() {
      let container = this.$el.querySelector("#messages");
      container.scrollTop = container.scrollHeight;
    },
  },
});
